.main_content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;

  padding: 10px;

  .file_actions {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    max-width: max-content;
  }

  .checkbox_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

}

.status {
  &__red {
    color: red;
  }

  &__blue {
    color: blue;
  }

  &__green {
    color: green;
  }
}

.profit {
  display: flex;
  justify-content: flex-end;
  width: 70px;

  &__red {
    color: red;
  }

  &__green {
    color: green;
  }
}

