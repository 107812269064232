// _variables.scss

$page-padding: 10px;
$page-margin: 10px;

$border-width: 1px;

$page-content-height: calc(100vh - var(--header-height) - var(--page-header-height) - var(--footer-height) - #{$border-width});


$sidebar-modal-width: 50%;
