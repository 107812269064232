.link {
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  color: var(--text-color);
  //width: 100%;
  &:hover {
  }

  &.active {
  }
}
