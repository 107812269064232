.button {
  cursor: pointer;
  transition: background-color 0.3s, border 0.3s, color 0.3s, box-shadow 0.3s;
  font-size: 16px;
  outline: none;
  min-width: 64px;
  border: 0;
  padding: 6px 16px;
  border-radius: 4px;
  height: max-content;
  width: max-content;

  &__fluid {
    width: 100%;
  }

  &.primary {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-color);
    border: 1px solid var(--button-primary-bg);
    box-shadow: var(--button-primary-shadow);

    &:hover {
      background-color: var(--button-primary-hover-bg);
      border: 1px solid var(--button-primary-hover-bg);
      box-shadow: var(--button-primary-hover-shadow);
    }

    &:disabled {
      color: var(--button-disabled-color);
      box-shadow: none;
      background-color: var(--button-disabled-bg);
    }
  }

  &.secondary {
    background-color: var(--button-secondary-bg);
    border: 1px solid var(--button-secondary-border-color);
    color: var(--button-secondary-color);

    &:hover {
      color: var(--button-secondary-color);
      background-color: var(--button-secondary-hover-bg);
      border: 1px solid var(--button-secondary-color);
    }

    &:disabled {
      color: var(--button-disabled-color);
      border: 1px solid var(--button-disabled-bg);
    }
  }
}
