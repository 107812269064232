.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.control {
  display: flex;
  align-items: center;
  gap: 10px;
}
