@import 'Base/assets/styles/_dimensions.variables.scss';

.content {
  padding: $page-padding;

  .fixed_size_list {
    height: calc(#{$page-content-height} - #{$page-padding} - #{$page-padding}) !important;
  }
}

.status {
  &__red {
    color: red;
  }

  &__blue {
    color: blue;
  }

  &__green {
    color: green;
  }
}

.profit {
  display: flex;
  justify-content: flex-end;
  width: 70px;

  &__red {
    color: red;
  }

  &__green {
    color: green;
  }
}

.item_icon {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.profit_amount {
  color: green;
  &__red {
    color: red;
  }

  &__green {
    color: green;
  }
}

.balance {
  color: green;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}