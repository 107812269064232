.icon {
  fill: currentColor;
  cursor: pointer;
  transition: all 0.3s, color 0s;

  path {
    transition: all 0.3s, color 0s;
  }
}

.active {
  path {
    d: path("M 5 25 A 2.0002 2.0002 0 1 0 5 29 L 45 29 A 2.0002 2.0002 0 1 0 45 25 L 5 25 z M 5 25 A 2.0002 2.0002 0 1 0 5 29 L 45 29 A 2.0002 2.0002 0 1 0 45 25 L 5 25 z M 5 25 A 2.0002 2.0002 0 1 0 5 29 L 45 29 A 2.0002 2.0002 0 1 0 45 25 L 5 25 z");
  }
}
