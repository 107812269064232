:root {
  --sidebar-width: 50px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: width 0.3s, align-items 0.3s, height 0.3s, opacity 0.3s;
  overflow: hidden;
  width: var(--sidebar-width);
  background-color: var(--sidebar-background-color);
  border-right: 1px solid var(--border-color);
  align-items: flex-end;

  .hamburger_icon {
    display: flex;
    justify-content: center;
    width: var(--sidebar-width);
    height: var(--header-height);

    &__open {
      width: 50px;
    }

    svg {
      margin: 10px 4px;
    }
  }

  .nav_wrap {
    //&::-webkit-scrollbar {
    //  display: none; // скрываем скроллбар
    //}
    //-ms-overflow-style: none;  // для IE 10+
    //scrollbar-width: none;  // для Firefox

    overflow-y: auto;
    width: 100%;

    nav {
      display: flex;
      justify-content: center;
      flex-direction: column;
      //width: 100%;
      border-top: 1px solid var(--border-color);
    }
  }
}



