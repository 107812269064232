@import 'Base/assets/styles/_dimensions.variables.scss';


.content {
  padding: $page-padding;

  .fixed_size_list {
    height: calc(#{$page-content-height} - #{$page-padding} - #{$page-padding}) !important;
  }

  .actions {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    z-index: 10;

  }
}

.file_actions {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  max-width: max-content;
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}


.item_icon {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.status {
  &__red {
    color: red;
  }

  &__blue {
    color: blue;
  }

  &__green {
    color: green;
  }
}

.profit {
  display: flex;
  justify-content: flex-end;
  width: 70px;

  &__red {
    color: red;
  }

  &__green {
    color: green;
  }
}

