@import 'Base/assets/styles/_dimensions.variables.scss';


.content {
  padding: $page-padding;

  .fixed_size_list {
    height: calc(#{$page-content-height} - #{$page-padding} - #{$page-padding}) !important;
  }

  .actions {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    z-index: 10;

  }
}

.item_icon {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
