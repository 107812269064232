.profit {
  display: flex;
  justify-content: flex-end;
  width: 70px;

  &__red {
    color: red;
  }

  &__green {
    color: green;
  }
}
