.main_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--background-color);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;

    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--text-color);
    }

    .controls {
      display: flex;
      gap: 10px;
    }
  }
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

.card h3 {
  margin-bottom: 10px;
  color: var(--primary-color);
}

.card p {
  color: var(--text-color);
  margin-bottom: 15px;
}

.link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}

