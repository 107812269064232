@import 'Base/assets/styles/_resolution.variables.scss';

.table {
  width: 100%;
  overflow: scroll;
  max-width: 100%;
  transition: width 1s;

  .tr {
    //:last-child {
    //  background-color: var(--background-color);
    //  .td {
    //    border-bottom: 0;
    //  }
    //}
    .actions {
      background: var(--background-color);
      z-index: 10
    }

    transition: width 1s;
  }

  .trHover {
    &:hover {
      background: var(--table-tr-hover-background-color);
      cursor: pointer;
    }

    transition: width 1s;
  }

  .th {
    padding: 8px !important;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ccc;
    transition: width 1s;
  }

  .th,
  .td {
    height: auto;
    width: 100px;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background: var(--background-color);
    word-break: break-all;
    transition: width 1s;
  }

  .td {
    display: flex !important;
    align-items: center;
    background: 0;
    transition: width 1s;
  }

  &.sticky {
    overflow: auto;

    .header {
      position: sticky;
      z-index: 1;
      width: fit-content;
      transition: width 1s;
    }

    .header {
      z-index: 4;
      top: 0;
      transition: width 1s;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      border-right: 1px solid #ccc;
    }

    [data-sticky-first-right-td] {
      border-left: 1px solid #ccc;
      box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.05);
    }
  }
}
