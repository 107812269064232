.accordion {
  overflow: hidden;
}

.accordionHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.arrow_wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--accordion-arrow-color);
    transition: transform 0.3s ease-in-out;

    &__open {
      transform: rotate(180deg);
    }

    &__active {
      border-top: 6px solid var(--primary-color);
    }
  }
}

.accordionContent {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

.background_label_group_active {
  background-color: var(--background-label-group-active-color);
}

.item_group {
  display: grid;
  grid-template-columns: max-content 0 auto;
  width: 100%;

  &__squeeze {
    grid-template-columns: 2.4rem 1fr 30px;
    grid-gap: 0.3rem;
  }

  padding: 7px 4px 7px 7px;
  border-bottom: 1px solid var(--border-color);
  color: var(--color);

  &__active {
    color: var(--primary-color);

    &:hover {
      background-color: var(--hover-background-color);
      color: var(--color);
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    width: calc(var(--sidebar-width) - 30px);

    &__squeeze {
      width: auto;
    }

    svg {
      color: var(--color);
      width: 30px;
      height: 30px;
    }
  }

  .text {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: var(--color);

    &__visible {
      opacity: 1;
    }

    &__hidden {
      width: 0;
      opacity: 0;
    }
  }

  .label_group_active {
    color: var(--primary-color);

    svg {
      color: var(--primary-color);
    }
  }
}

.item_menu {
  display: grid;
  grid-template-columns: max-content 0;
  grid-gap: 0;
  text-decoration: none;
  color: var(--color);
  transition: grid-gap 0.3s;
  padding: 5px 0;

  &__squeeze {
    grid-template-columns: 2.4rem 1fr;
    grid-gap: 0.3rem;
    padding: 5px 0 5px 10px;
  }

  &__active {
    color: var(--primary-color);
    background-color: var(--primary-background-color);

    &:hover {
      background-color: var(--hover-background-color);
      color: var(--color);
    }
  }

  &:hover {
    color: var(--color);
    background-color: var(--hover-background-color);
  }

  .icon {
    display: flex;
    justify-content: center;
    width: calc(var(--sidebar-width));

    &__squeeze {
      width: auto;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .text {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &__visible {
      opacity: 1;
    }

    &__hidden {
      width: 0;
      opacity: 0;
    }
  }
}
