@import 'Base/assets/styles/_dimensions.variables.scss';

:root {
  --header-height: auto;
  --header-translateY: 0;
  --header-height-border: 1px;
}


.header_wrap {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  right: 0;
  background-color: var(--header-background-color);
  z-index: 10;
  transition: left 0.3s, transform 0.3s;
  padding: 0 2rem;
  border-bottom: $border-width solid var(--border-color);
  color: var(--text-color);
  transform: translateY(var(--header-translateY));
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;

  .control {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}