.layout {
  background: var(--background-color);

  .main_and_footer_wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--header-height) - var(--page-header-height));
    margin-top: calc(var(--header-height) + var(--page-header-height));
    margin-left: var(--sidebar-width);
    transition: margin-left 0.3s;
  }
}
