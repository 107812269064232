.input_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: var(--text-color);
  position: relative;
  height: max-content;

  label {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .required {
    color: var(--required-color);
  }

  input {
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    background: var(--input-background-color);
    color: var(--input-color);

    &:hover {
      border-color: var(--input-border-color-hover);
    }

    &:focus {
      border-color: var(--input-border-color-focus);
    }
  }

  input[disabled] {
    background-color: var(--input-background-color-disabled);
    color: var(--input-color-disabled);
    border-color: var(--input-border-color-disabled);
    cursor: not-allowed;
    opacity: 0.7;

    &:hover,
    &:focus {
      border-color: var(--input-border-color-disabled);
    }
  }


  .error {
    position: absolute;
    color: var(--error-color);
    font-size: 14px;
    bottom: -20px;
    margin: 0;
  }
}
