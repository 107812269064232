.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-overlay-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal {
  background-color: var(--modal-background-color);
  padding: 1em;
  border-radius: 4px;
}

.modal_header {
}

.modal_body {
}

