:root {
  --footer-height: 60px
}

.footer {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  text-align: center;
  background-color: var(--footer-background-color);
  border-top: 1px solid var(--border-color);
  height: var(--footer-height);

  p {
    margin: 0;
  }
}