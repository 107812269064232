/* Основная оболочка переключателя */
.themeSwitchWrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  padding: 0 6px;
  gap: 4px;
}

.themeSwitch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.themeSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc;
  border-radius: 34px;
  transition: background-color 0.4s;
  padding: 2px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  transition: transform 0.4s;
  transform: translateX(4px);
  top: 50%;
  transform: translateY(-50%);
}

/* Перемещение ползунка */
input:checked + .slider:before {
  transform: translateX(26px) translateY(-50%);
  background-color: #f5f5f5; /* Цвет ползунка в активном состоянии */
}

input:checked + .slider {
  background-color: #444;
}

/* Иконка темы (универсальная) */
.themeIcon {
  font-size: 20px;
  color: var(--text-color);
}

