
.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;

  .item {
    display: flex;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: max-content;

  .block {
    display: flex;
    gap: 20px;

    .item {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-gap: 20px;


      .text {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 38px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    //float: right;
  }
}
