.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: max-content;

  .block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    //.item {
    //  display: flex;
    //  flex-direction: column;
    //  gap: 15px;
    //}
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
}
