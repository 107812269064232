@import 'Base/assets/styles/_resolution.variables.scss';

.table_container {
  height: 100%;
  overflow-x: scroll;
  width: 100%;

  .table {
    border-collapse: collapse;
    width: max-content;

    thead {
      height: 48px;
      background-color: var(--table-background-color);

      th {
        padding: 0.4rem 0.8rem;
        border-bottom: 0.05rem solid #ccc;
        text-align: left;
        color: var(--table-text-color);

        span {
          margin-left: 0.5rem;
          color: #555;
        }
        .header_content {
          max-width: 160px;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: var(--table-tr-nth-child-odd-background-color);
        }

        &:nth-child(even) {
          background-color: var(--table-tr-nth-child-even-background-color);
        }

        &:hover {
          background-color: var(--table-tr-hover-background-color);
        }
      }

      td {
        padding: 0.4rem 0.8rem;
        border-bottom: 0.05rem solid #ccc;
        text-align: left;
      }
    }
  }
}

.buttons {
  display: flex;
  gap: 2rem;
}

