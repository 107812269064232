@import 'Base/assets/styles/_dimensions.variables.scss';


.content {
  padding: $page-padding;

  .fixed_size_list {
    height: calc(#{$page-content-height} - #{$page-padding} - #{$page-padding}) !important;
  }

  .actions {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    z-index: 10;

  }
}

.full_name {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #0072E5;
  }
}

.title_department {
  display: flex;
  flex-direction: column;

  .department {
    color: var(--users-department-color);
  }
}
