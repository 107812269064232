:root {
  /* Default light theme */
  --text-color: #000;
  --background-color: linear-gradient(135deg, #F0F7FF, #ffffff);
  --header-background-color: #f1f1f1;
  --footer-background-color: #f1f1f1;
  --border-color: #adadad;

  /* Table */
  --table-background-color: #ffffff;
  --table-text-color: black;
  --table-tr-nth-child-odd-background-color: #f9f9f9;
  --table-tr-nth-child-even-background-color: white;
  --table-tr-hover-background-color: #f1f3f5;

  /* Input */
  --input-color: #333;
  --input-border-color: #ccc;
  --input-border-color-hover: #bbb;
  --input-background-color: white;
  --input-background-color-disabled: #f3f4f6;
  --input-color-disabled: #b0b4ba;
  --input-border-color-disabled: #d1d4da;

  --selected-input-background-color: white;
  --selected-input-text-color: black;
  --option-background-color: white;
  --option-background-color-hover: #f1f2f8;
  --option-color: black;
  --option-color-hover: black;
  --modal-overlay-background-color: rgba(0, 0, 0, 0.7);
  --modal-background-color: #ffffff;
  --sidebar-background-color: #f1f1f1;
  --sidebar-item-background-color-hover: #ababab;
  --sidebar-item-background-color-active: #969696;

  /* Additional styles */
  --primary-color: #0072E5;
  --primary-background-color: #F0F7FF;
  --color: #0B0D0E;
  --hover-background-color: rgba(0, 127, 255, 0.12);
  --accordion-arrow-color: #5e5e5e;
  --background-label-group-active-color: rgba(249, 250, 255, 0.91);

  /* Buttons */
  --button-primary-bg: rgb(25, 118, 210);
  --button-primary-color: #ffffff;
  --button-primary-hover-bg: rgb(21, 101, 192);
  --button-primary-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  --button-primary-hover-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  --button-disabled-color: rgba(0, 0, 0, 0.26);
  --button-disabled-bg: rgba(0, 0, 0, 0.12);
  --button-secondary-bg: white;
  --button-secondary-border-color: rgba(25, 118, 210, 0.5);
  --button-secondary-color: rgb(25, 118, 210);
  --button-secondary-hover-bg: rgba(25, 118, 210, 0.04);

  /* Checkbox */
  --checkbox-border-color: #a1a1a1;
  --checkbox-bg-color: #a1a1a1;

  /* Dropdown */
  --dropdown-bg-color: #f9f9f9;
  --dropdown-box-shadow-color: rgba(0, 0, 0, 0.2);
  --dropdown-button-hover-bg-color: #f1f1f1;

  /* Input date */
  --input-date-required-color: red;
  --input-date-border-focus-color: blue;
  --input-date-error-color: red;

  /* Error */
  --error-color: red;

  --required-color: red;

  /* Overlay and Sidebar */
  --overlay-bg-color: rgba(0, 0, 0, 0.5);
  --sidebar-bg-color: #fff;

  /* Devices and Users */
  --device-color: #00000099;
  --users-department-color: #00000099;
}

[data-theme='dark'] {
  /* Default dark theme */
  --text-color: #ffffff;
  --background-color: linear-gradient(135deg, #2e2e2e, #444);
  --header-background-color: #2e2e2e;
  --footer-background-color: #2e2e2e;
  --border-color: #555;

  /* Table */
  --table-background-color: #444;
  --table-text-color: #ffffff;
  --table-tr-nth-child-odd-background-color: #3d3d3d;
  --table-tr-nth-child-even-background-color: #2e2e2e;
  --table-tr-hover-background-color: #565656;

  /* Input */
  --input-color: #ffffff;
  --input-border-color: #555;
  --input-border-color-hover: #777;
  --input-background-color: #444;
  --input-background-color-disabled: #444;
  --input-color-disabled: #777;
  --input-border-color-disabled: #555;

  --selected-input-background-color: #565656;
  --selected-input-text-color: #ffffff;
  --option-background-color: #444;
  --option-background-color-hover: #3d3d3d;
  --option-color: #ffffff;
  --option-color-hover: #ddd;
  --modal-overlay-background-color: rgba(255, 255, 255, 0.2);
  --modal-background-color: #2e2e2e;
  --sidebar-background-color: #2e2e2e;
  --sidebar-item-background-color-hover: #555;
  --sidebar-item-background-color-active: #464646;

  /* Additional styles */
  --primary-color: #0072E5; /* Remained the same as it's a brand color */
  --primary-background-color: #444;
  --color: #ffffff;
  --hover-background-color: rgba(255, 255, 255, 0.12);
  --accordion-arrow-color: #cccccc;
  --background-label-group-active-color: rgba(57, 58, 63, 0.91);

  /* Buttons */
  --button-primary-bg: rgb(25, 118, 210);
  --button-primary-color: #ffffff;
  --button-primary-hover-bg: rgb(21, 101, 192);
  --button-primary-shadow: rgba(0, 0, 0, 0.3) 0px 3px 1px -2px, rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.22) 0px 1px 5px 0px;
  --button-primary-hover-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px -1px, rgba(0, 0, 0, 0.24) 0px 4px 5px 0px, rgba(0, 0, 0, 0.22) 0px 1px 10px 0px;
  --button-disabled-color: rgba(255, 255, 255, 0.3);
  --button-disabled-bg: rgba(255, 255, 255, 0.12);
  --button-secondary-bg: #444;
  --button-secondary-border-color: rgba(25, 118, 210, 0.5);
  --button-secondary-color: #0072E5;
  --button-secondary-hover-bg: rgba(25, 118, 210, 0.1);

  /* Checkbox */
  --checkbox-border-color: #777;
  --checkbox-bg-color: #777;

  /* Dropdown */
  --dropdown-bg-color: #3d3d3d;
  --dropdown-box-shadow-color: rgba(0, 0, 0, 0.3);
  --dropdown-button-hover-bg-color: #444;

  /* Input date */
  --input-date-required-color: #ff7777;
  --input-date-border-focus-color: #0072E5;
  --input-date-error-color: #ff7777;

  /* Error */
  --error-color: #ff7777;

  --required-color: #ff7777;

  /* Overlay and Sidebar */
  --overlay-bg-color: rgba(255, 255, 255, 0.1);
  --sidebar-bg-color: #2e2e2e;

  /* Devices and Users */
  --device-color: #ffffff80;
  --users-department-color: #ffffff80;
}

