.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: max-content;

  .block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    .item {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
}

