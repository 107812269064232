//:root {
//  --selected-input-text-color: #ffffff;
//}
//
//[data-theme='dark'] {
//  --selected-input-text-color: #ffffff;
//}

.login_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
  //background-image: url(./map-image.jpg);
  background-image: url(login.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  div {
    color: white;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;

    .logo {
      display: flex;
      align-items: center;
      gap: 10px;
      color: white;
    }
  }

  .form_container {
    padding: 20px;
    border-radius: 8px;
    background-color: var(--modal-background-color);
    box-shadow: 0 4px 10px var(--dropdown-box-shadow-color);
    width: 400px;

    .form_title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: var(--text-color);
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
