@import '_resolution.variables';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;

  button {
    font: inherit;
  }

  input {
    font: inherit;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


// _extraSmallScreens.scss
@media #{$extra-small-screens} {
  html {
    font-size: 12px;
  }
  body {
    font-size: 1rem;  // пример для extra-small экранов
  }
}

// _smallScreens.scss
@media #{$small-screens} {
  html {
    font-size: 14px;
  }
  body {
    font-size: 0.8rem;  // пример для small экранов
  }
}

// _mediumScreens.scss
@media #{$medium-screens} {
  html {
    font-size: 16px;
  }
  body {
    font-size: 0.8rem;  // как вы и хотели для medium экранов
  }
}

// _largeScreens.scss
@media #{$large-screens} {
  html {
    font-size: 18px;
  }
  body {
    font-size: 0.8rem;  // пример для large экранов
  }
}

// _extraLargeScreens.scss
@media #{$extra-large-screens} {
  html {
    font-size: 20px;
  }
  body {
    font-size: 0.8rem;  // пример для extra-large экранов
  }
}

// _xxlScreens.scss
@media #{$xxl-screens} {
  html {
    font-size: 20px;
  }
  body {
    font-size: 0.8rem;  // пример для xxl экранов
  }
}

// _xxxlScreens.scss
@media #{$xxxl-screens} {
  html {
    font-size: 20px;
  }
  body {
    font-size: 0.8rem;  // пример для xxxl экранов
  }
}