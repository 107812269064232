.checkbox_container {
  .checkbox_input {
    display: none;

    &:checked + label .checkbox {
      &:after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  .checkbox {
    border: 0.1rem solid #a1a1a1;
    border-radius: 0.3rem;
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      width: 0.6rem;
      height: 0.6rem;
      background-color: #a1a1a1;
      border-radius: 0.3rem;
      transition: 0.3s;
    }
  }

  label {
    display: flex;
    align-items: center;
  }

  .title {
    margin-left: 0.4rem;
    vertical-align: middle;
    cursor: pointer;
  }
}