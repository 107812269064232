.dropdown_container {
  position: relative;
  display: inline-block;

  .button {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 0;
    background: 0;
    cursor: pointer;
    color: var(--text-color);
  }
}

.dropdown_content {
  background-color: var(--dropdown-bg-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px var(--dropdown-box-shadow-color);
  border-radius: 8px;

  button {
    padding: 12px 16px;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: var(--text-color);

    &:hover {
      background-color: var(--dropdown-button-hover-bg-color);
      border-radius: var(--dropdown-border-radius);
    }
  }
}
