.cryptoWidget {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.cryptoCard {
  margin: 1em;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px; // Made the card smaller
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
}

.cryptoInfo {
  margin-top: 0.5em;
  font-size: 0.8em;

  div {
    margin-bottom: 0.3em; // Added to separate div elements
  }
}

.red {
  color: red;
}

.green {
  color: green;
}
