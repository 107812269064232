.transactions_info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;

  padding: 10px;

  .turnover {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profit_info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    span {
      color: green;
    }
  }
}
