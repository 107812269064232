@import 'Base/assets/styles/_dimensions.variables.scss';

:root {
  --page-header-height: 0px;
}

.header {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  border-bottom: $border-width solid var(--border-color);
  margin-top: var(--header-height);
  padding: 6px 9px 6.3px;
  //padding: 9px 9px 8.5px;
  z-index: 5;
  background: var(--background-color);
  left: var(--sidebar-width);
  transition: transform 0.3s, translateY 0.3s, height 0.3s, margin-top 0.3s, left 0.3s;
  top: 0;
  right: 0;
  transform: translateY(calc(var(--header-translateY) + 1px));
}