.setting_profile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
  }

  .form_child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  button {
    width: max-content;
  }
}

.text {
  display: flex;
  align-items: center;
  height: 100%;
}