@import 'Base/assets/styles/_dimensions.variables.scss';

.page_container {
  position: fixed;
  overflow: auto;
  width: calc(100% - var(--sidebar-width));
  height: $page-content-height;
  transition: height 0.3s, width 0.3s;
}

